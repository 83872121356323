export const homePageStats = [
  { title: 'event impressions', count: 200, postfix: 'M+' },
  { title: 'events catered', count: 200, postfix: '+' },
  { title: 'fan interactions', count: 25, postfix: 'M+' },
];

export const aboutUsPageStats = [
  { title: 'Active Users', count: 23450 },
  { title: 'Total Events', count: 1795 },
  { title: 'Active Users', count: 798 },
];
