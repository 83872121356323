import React, { useState } from 'react';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import config from '../config/config';
import 'yup-phone';

const ContactFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  contact: Yup.string().matches(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
    'Phone number is not valid'
  ),
});

export default function ContactForm() {
  const [successMessage, setSuccessMessage] = useState('');
  const sendEmail = (values: any, formikHelpers: FormikHelpers<any>) => {
    const requestObject = {
      receiverEmail: config.REACT_APP_EMAILJS_RECEIVER,
      senderEmail: values.email,
      senderContact: values.contact,
      url: config.REACT_APP_EMAILJS_WEBSITE
    };
    emailjs.init(config.REACT_APP_EMAILJS_USERID);
    emailjs
      .send(
        config.REACT_APP_EMAILJS_SERVICEID,
        config.REACT_APP_EMAILJS_TEMPLATEID,
        requestObject
      )
      .then(
        (result) => {
          //console.log(result.text);
          setSuccessMessage('Your request is submitted sucessfully!');
          formikHelpers.resetForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <h2 className='form-heading'>
        We’d love to show you how your brand can work with us and do much much
        more...
      </h2>
      <Formik
        initialValues={{ email: '', contact: '' }}
        onSubmit={sendEmail}
        validationSchema={ContactFormSchema}
        render={({ errors, touched }) => (
          <Form>
            <form className='contact-form'>
              <label htmlFor='' className='input-wrapper'>
                <span className='--label'>Work Email</span>
                <Input
                  name='email'
                  type={'text'}
                  className={
                    errors.email && touched.email ? 'error' : 'text-input'
                  }
                  placeholder='Example.mail@email.com'
                  onFocus={() => {
                    setSuccessMessage('');
                  }}
                />
              </label>
              <label htmlFor='' className='input-wrapper'>
                <span className='--label'>Contact Number (Optional)</span>
                <Input
                  name='contact'
                  type='text'
                  className={
                    errors.contact && touched.contact ? 'error' : 'text-input'
                  }
                  placeholder='+1-123-123-1234'
                  onFocus={() => {
                    setSuccessMessage('');
                  }}
                />
              </label>
              {/* <label htmlFor="" className="input-wrapper">
                <span className="--label">Use Case</span>
                <span className="--polygon"></span>
                <Select
                  name="selectedOption"
                  className="custom-select"
                  placeholder="Select One"
                  showArrow={false}
                  // onChange={handleChange}
                  defaultValue="option1"
                // value={selectedValue}
                >
                  <option value="option1" className="select-option">
                    Option 1
                  </option>
                  <option value="option2" className="select-option">
                    Option 2
                  </option>
                </Select>
              </label> */}
              <SubmitButton className={`custom-button filled `}>
                Schedule a demo
              </SubmitButton>

              {successMessage ? (
                <span className='message'>{successMessage}</span>
              ) : null}
            </form>
          </Form>
        )}
      />
    </>
  );
}
