import { useCallback, useEffect, useState } from 'react';
import { useWebPSupportCheck } from 'react-use-webp-support-check';

import Button from 'components/base/button';
import { heroSectionData } from 'jsonObjets';

const { relatedData } = heroSectionData;

export default function HomeHero() {
  const [slideCount, setSlideCount] = useState(0);
  const [slideTimer, setSlideTimer] = useState<any>();
  const isWebPSupported = useWebPSupportCheck();

  const startSlideshow = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setSlideCount((slideCount + 1) % relatedData.descriptions.length);
    }, 6000);

    setSlideTimer(timeoutId);
  }, [slideCount]);

  const handleDotClick = useCallback(
    (index) => {
      clearTimeout(slideTimer);

      setSlideCount(index);

      startSlideshow();
    },
    [slideTimer, setSlideCount]
  );

  useEffect(() => {
    startSlideshow();

    return () => {
      clearTimeout(slideTimer);
    };
  }, [slideCount]);

  return (
    <main className='main-home-hero'>
      <section className='hero-section main-container'>
        <section className='text-section'>
          <p className='hero-text '>{relatedData.title}</p>
          <div>
            {relatedData.descriptions.map((data, index) => {
              const { text } = data;
              return (
                <p
                  className={`hero-text --bold --animated --main ${
                    index === slideCount ? 'active' : ''
                  }`}
                  key={index + data.src}
                >
                  {text}
                </p>
              );
            })}
          </div>
          <Button link='/about#mail-sent' text='Schedule a demo' />
        </section>
        <section className='images-section'>
          <section className='fading-images-container'>
            {relatedData.descriptions.map((data, index) => {
              const { src, srcWebP, alt, classes } = data;
              return (
                <img
                  src={isWebPSupported && srcWebP ? srcWebP : src}
                  alt={alt}
                  className={`fading-image --animated ${classes} ${
                    index === slideCount ? 'active' : ''
                  }`}
                  key={index + data.src}
                />
              );
            })}
          </section>
          <figure className='dots-wrapper'>
            {relatedData.descriptions.map((_, index) => (
              <span
                key={index}
                className={`dots ${index === slideCount && `active`}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </figure>
          <img
            src='https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/ellipse.webp'
            alt='ellipse'
            className='ellipse --top-left'
          />
          <img
            src='https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/ellipse.webp'
            alt='ellipse'
            className='ellipse --bottom-right'
          />
        </section>
      </section>
    </main>
  );
}
