import Link from "next/link";
interface ComponentProps {
  text: string;
  link?: string;
  filled?: boolean;
}
export default function Button(props: ComponentProps) {
  const { text, filled, link } = props;
  return (
    <Link href={link ? link : "/"}>
      <button
        type="button"
        className={`custom-button
        ${filled ? "filled" : "outlined"}
        `}>
        {text}
      </button>
    </Link>
  );
}
