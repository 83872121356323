import { caseStudiesBannerData } from 'jsonObjets';

interface contentProps {
  content?: boolean;
}
export default function CaseStudiesBanner(props: contentProps) {
  const { title } = caseStudiesBannerData;
  const { content } = props;

  return (
    <main className='main-case-studies-hero'>
      <section className='main-container case-studies-banner'>
        {content !== false && <h1 className='main-heading'>{title}</h1>}
        <figure className='background'>
          <span className='circle circle1'></span>
          <span className='circle circle2'></span>
          <span className='circle circle3'></span>
          <span className='circle circle4'></span>
        </figure>
      </section>
    </main>
  );
}
