export const testimonialsData = {
  title: (
    <>
      We make every <br /> <span className='--blue'>word of yours count!</span>
    </>
  ),

  testimonials: [
    {
      description:
        'After integrating FanKave solutions into Oracle Arena, we saw significant improvement in fan engagement and interaction!',
      author: 'Scott McKibben',
      designation: 'CEO',
      company: 'Oracle Arena',
      classes: 'verticle-testimonial',

      clientImage: {
        show: true,
        src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/client1.png',
      },
      extraDesignElement: {
        show: true,
        src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/commas.png',
      },
    },
    {
      description:
        'We are VERY HAPPY with the Social Media experiences that FanKave provided!  The authenticity of the user stories automatically selected at scale has provided added dynamism and is a big hit with our audience!',
      author: 'Rob Neumann',
      designation: 'GM, Global Sponsorship Activations',
      classes: 'card --top-left',
      company: 'Cisco',
      clientImage: {
        show: false,
        src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/client2.png',
      },
      extraDesignElement: {
        show: false,
        src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/commas.png',
      },
    },
    {
      description:
        'FanKave platform’s ability to automatically select the right content that resonated with our audience simplified the management part for our team. Multitude of unique experiences that seamlessly ran across large screens of different sizes was a pleasant surprise. Social Leaderboard definitely made social engagement fun and competitive as we saw increased attendee engagement with a lot of people saying good things about the experiences.',
      author: 'Ian Wikramanayake',
      designation: 'Social Media Manager',
      company: 'Pure Storage',
      classes: 'card --bottom-right',
      clientImage: {
        show: false,
        src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/client1.png',
      },
      extraDesignElement: {
        show: false,
        src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/commas.png',
      },
    },
  ],
};
