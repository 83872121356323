import type { AppProps } from "next/app";
import { Layout } from "components";
import "styles/index.scss";
import "antd/dist/antd.css";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Fankave</title>
        <meta name="description" content="A Fankave website" />
        <link rel="icon" href="https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fankave-favicon.ico" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-XZSC2VT8HL"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-XZSC2VT8HL', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
        <script src="https://storage.googleapis.com/fk-gp/ZoomInfoScripts/fankave-script.js"></script>
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}
export default MyApp;
