import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { testimonialsData } from 'jsonObjets';
import { useEffect, useState } from 'react';

const responsive = {
  tablet: {
    breakpoint: { max: 1024, min: 0 },
    items: 1,
  },
};

interface componentProps {
  customClasses: string;
  description: string;
  author: string;
  designation: string;
  clientImage?: string;
  extraDesignElement?: string;
  company?: string;
}

const TextimonialCard = (props: componentProps) => {
  const {
    author,
    customClasses,
    description,
    designation,
    clientImage,
    extraDesignElement,
    company,
  } = props;

  return (
    <section className={`${customClasses}`}>
      <article className='content'>
        <p className='description'>{description}</p>
        <p className='author'>{author}</p>
        <p className='designation'>{designation}</p>
        <p className='company'>{company}</p>
        {extraDesignElement && (
          <span className='extra-design-element'>
            <img src={extraDesignElement} alt='commas design' />
          </span>
        )}
      </article>
    </section>
  );
};

const TestimonialCarousel = (props: componentProps) => {
  const {
    author,
    description,
    designation,
    clientImage,
    company,
    extraDesignElement,
  } = props;

  return (
    <section className='carousel-card-container '>
      <article className='carousel-card'>
        <img
          src={clientImage}
          alt='client image'
          className='testimonial-image-container'
        />
        <p className='description'>{description}</p>
        <p className='author'>{author}</p>
        <p className='designation'>{designation}</p>
        <p className='company'>{company}</p>
        {extraDesignElement && (
          <span className='extra-design-element'>{extraDesignElement}</span>
        )}
      </article>
    </section>
  );
};

export default function TestimonialSection() {
  const { title, testimonials } = testimonialsData;

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }

      window.addEventListener('resize', updateSize);
      updateSize();
    }, [
      typeof window != 'undefined' && window.innerWidth,
      typeof window != 'undefined' && window.innerHeight,
    ]);

    return size;
  }

  const [width] = useWindowSize();

  return (
    <main className='main-container testimonial-section'>
      <h1 className='main-heading --dark'>{title}</h1>
      <section className='monitor'>
        <section className='clients'>
          <img
            src='https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/client1.png'
            alt=''
            id='client1'
            className='client'
          />
          <img
            src='https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/client2.png'
            alt=''
            id='client2'
            className='client'
          />
        </section>

        {testimonials.map((data, index) => (
          <TextimonialCard
            author={data.author}
            customClasses={data.classes}
            description={data.description}
            designation={data.designation}
            key={index + data.author}
            clientImage={data.clientImage?.show ? data.clientImage?.src : ''}
            extraDesignElement={
              data.extraDesignElement?.show ? data.extraDesignElement?.src : ''
            }
            company={data.company}
          />
        ))}
      </section>
      <Carousel
        responsive={responsive}
        centerMode={width > 500 && true}
        autoPlay={true}
        autoPlaySpeed={6000}
        arrows={true}
        infinite={true}
        customTransition={'transform 600ms ease-in-out'}
        transitionDuration={600}
        showDots={true}
        containerClass='testimonial-carousel'
      >
        {testimonials.map((data, index) => (
          <TestimonialCarousel
            author={data.author}
            customClasses={data.classes}
            description={data.description}
            designation={data.designation}
            key={index + data.author}
            clientImage={data.clientImage?.src}
            extraDesignElement={data.extraDesignElement?.src}
            company={data.company}
          />
        ))}
      </Carousel>
    </main>
  );
}
