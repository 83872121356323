import CountingAnimation from "./CountingAnimation";
interface componentProps {
  stats: {
    title: string;
    count: number;
    postfix?: string;
  }[];
}

export default function Stats(props: componentProps) {
  const { stats } = props;

  return (
    <main className="main-stats-section">
      <section className="main-container stats-container">
        {stats.map((data, index) => {
          const count = Number(data.count);
          return (
            <article className="stats-wrapper" key={index + data.title}>
              <section className="stats">
                <h1 className="count">
                  {/* <CountUp end={count} start={0} separator="," delay={1} /> */}
                  <CountingAnimation start={0} end={count}/>
                  <span>{data?.postfix}</span>
                </h1>
                <p className="title">{data.title}</p>
              </section>
            </article>
          );
        })}
      </section>
    </main>
  );
}
