import { caseStudiesData, connectSectionData } from 'jsonObjets';
import { DefaultCard } from 'components';
import Button from 'components/base/button';
import { useMemo } from 'react';

interface componentProps {
  detailsPage?: boolean;
  currentCaseStudyId?: number;
}

export default function ConnectSection(props: componentProps) {
  const { detailsPage, currentCaseStudyId = -1 } = props;
  const { title, detailsTitle } = connectSectionData;
  const { caseStudies } = caseStudiesData;

  const allCaseStudies = [...caseStudies];

  const renderCaseStudies = useMemo(() => {
    return allCaseStudies
      .sort(() => Math.random() - 0.5)
      .filter((caseStudy) => caseStudy.id !== currentCaseStudyId)
      .slice(0, 3)
      .map((data, index) => (
        <DefaultCard
          caseStudyId={data.id}
          description={data.description}
          image={data.cardImg}
          imageWebP={data.cardImgWebP}
          title={data.title}
          customClasses='case-studies'
          link='/'
          key={index + data.title}
          enableClick={true}
        />
      ));
  }, [allCaseStudies, currentCaseStudyId]);

  return (
    <main className='main-connect-section'>
      <section className='main-container connect-section'>
        <h1 className='main-heading --dark'>
          {detailsPage ? detailsTitle : title}
        </h1>

        <section className='cards-container'>{renderCaseStudies}</section>

        {!detailsPage && (
          <Button
            text='View All Case Studies'
            link='/case-studies#case-studies'
          />
        )}
      </section>
    </main>
  );
}
