export const aboutUsHeroData = {
  title: (
    <>
      Bring magic to <span className='--blue'>your brand</span> in the real
      world
      <br />
      by transforming
      <span className='--blue'> social content</span> to
      <span className='--blue'> visual stories.</span>
    </>
  ),
};
