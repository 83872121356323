export const aboutCompannyData = {
  title: "About Fankave",
  src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/about-fankave.webp",
  descripion: (
    <>
      Founded in Silicon Valley, the heart of many great ideas, FanKave was
      started by an experienced team out of Netflix and Microsoft. Fankave’s
      goal is simple – bring
      <br /> magic to your brands in the real world by transforming social
      content to visual <br />
      stories.
      <br />
      <br /> We have come a long way since and our technology has enabled
      several clients
      <br /> around the world to drive higher engagement with their audience. If
      you want to be
      <br /> part of our journey, send your resume to jobs@fankave.com
    </>
  ),
};

export const happyUsersData = {
  title: "Happy users worldwide",
  count: "25000+",
  src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/happy-users.webp",
  descripion: (
    <>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
    </>
  ),
};
