import { caseStudiesData } from 'jsonObjets';
import { DefaultCard } from 'components';

export default function CaseStudies() {
  const { caseStudies } = caseStudiesData;
  return (
    <main className='main-case-studies'>
      <section
        className='main-container case-studies-section'
        id='case-studies'
      >
        <section className='case-studies-container'>
          {caseStudies.map((data, index) => (
            <DefaultCard
              caseStudyId={data.id ? data.id : 1}
              image={data.img}
              imageWebP={data.imgWebP}
              title={data.title}
              description={data.description}
              key={index + data.title}
              tags={data.tags}
              logo={data.logo}
              customClasses='--shadow case-study --link'
              customClass='case-studies'
              type='case-study'
              enableClick={true}
            />
          ))}
        </section>
      </section>
    </main>
  );
}
