export const caseStudiesData = {
  title: <>Case Studies</>,

  caseStudies: [
    {
      id: 1,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/cisco-banner.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/cisco-banner.webp',
      cardImg: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/cisco.webp',
      cardImgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/cisco.webp',
      title: 'Amplify Global Brand',
      detailsTitle: (
        <>
          <span className='--white'>Amplify Global Brand</span>
        </>
      ),
      description:
        'In Cisco Live, there are more than 1000 sessions, labs, training events that are available to around 35K attendees at the venue.',
      detailsDescription:
        'In Cisco Live, there are more than 1000 sessions, labs, training events that are available to around 35K attendees at the venue.',
      logo: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/cisco-logo.webp',
      tags: [],
      stats: [
        { count: '8M+', title: 'Impressions ' },
        { count: '21%', title: 'Increase in engagement' },
        { count: '35K+', title: 'Attendees' },
      ],
      problem: {
        title: 'The Problem',
        description:
          'During Cisco Live, there are more than 1000 sessions, labs, training events that are available to around 35K attendees at the venue. With so many events taking place at the same time, it is difficult to get people excited to share their experience and sentiment about the experience. To overcome this issue Cisco wanted to champion their social media handles to get attendees excited and create external buzz about the event. Their objectives were:',
        points: [
          {
            title: '',
            list: [
              'Push for a unique attendee experience',
              'Increase attendee engagement and make it enjoyable for people to participate',
            ],
          },
        ],
      },
      solution: {
        title: 'The Solution',
        description:
          'FanKave optimized Cisco’s social media content by displaying multiple experiences all around the venue. These experiences highlighted attendee’s social media posts making the event more personal and enticing participants to post on social media so they could see their posts in the venue.',
        points: [
          {
            title: '',
            list: [
              'Relevant content was automatically chosen based on location and context (like keynote, breakout sessions etc.) ',
              'Daily Social Leaderboard gamified the engagement and made it competitive and fun ',
              'Real-time data and analytics was displayed visually to show the scale of social engagement, amplifying the success of the event ',
              'Time-sliced experiences as part of a playlist powered by FanKave’s Content Management System gave the events team greater control over what to show on the screens ',
              'Robust ML algorithm that automatically filtered malicious or unflattering content, keeping the in-venue experience positive and highlighting the key areas the brand wanted to promote',
            ],
          },
        ],
      },
      resultTitle: 'The Result',
      results: [
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/increase.webp',
          description: (
            <>
              Cisco Live in Orlando saw an{' '}
              <span className='--blue'>increase</span> in{' '}
              <span className='--blue'>social engagement</span> across both
              Twitter and Instagram
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/audience.webp',
          description: (
            <>
              Experiences like pre-keynote highlights inside the keynote hall
              greatly{' '}
              <span className='--blue'>increased audience engagement</span> and
              excitement before keynote sessions
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/star.webp',
          description: (
            <>
              Goal of <span className='--blue'>15% increase</span> in engagement
              was easily beat and the resulting increase was close to{' '}
              <span className='--blue'>21%</span>
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/security.webp',
          description: (
            <>
              Our fully managed <span className='--blue'>on-site service</span>{' '}
              including auto-moderation (powered by our Social Graph) proved a{' '}
              <span className='--blue'>game changer</span> for the{' '}
              <span className='--blue'>social media events</span> team by
              letting them focus on other important activations during the event
            </>
          ),
        },
      ],
    },
    {
      id: 2,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/pure-storage-banner.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/pure-storage-banner.webp',
      cardImg: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/pure-storage.webp',
      cardImgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/pure-storage.webp',
      title: 'Redefine Social Media Amplification',
      detailsTitle: (
        <>
          <span className='--white'>Re-define Social Media Amplification</span>
        </>
      ),
      description:
        'In 2019, Pure Storage had its biggest ever annual event in Austin and for the first time, the event marketing team wanted to focus on social media  as one of the primary channels to engage with the attendees.',
      detailsDescription:
        'In 2019, Pure Storage had its biggest ever annual event in Austin and for the first time, the event marketing team wanted to focus on social media  as one of the primary channels to engage with the attendees.',
      logo: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/pure-storage-logo.webp',
      tags: [],
      stats: [
        { count: '5X', title: 'Increase in engagement' },
        { count: '4K+', title: 'Attendees' },
      ],
      problem: {
        title: 'The Problem',
        description: (
          <>
            In 2019, Pure Storage had its biggest ever annual event in Austin
            and for the first time, the event marketing team wanted to focus on
            social media as one of the primary channels to engage with the
            attendees. As this was their first time highlighting their social
            media engagement, it was paramount that the brand and event be
            highlighted in the most positive light. Their objectives and requirements 
            for the event were:
          </>
        ),
        points: [
          {
            title: '',
            list: [
              'Fully managed service',
              'Reward and encourage attendee engagement',
              'Unique experiences to increase attendee satisfaction',
              "Branded creatives to amplify Pure Storage's social presence",
            ],
          },
        ],
      },
      solution: {
        title: 'The Solution',
        description:
          'FanKave’s strategy for this event was to showcase different social experiences that were contextually relevant to massively improve attendee engagement. Relevant content was automatically chosen based on location and context (like keynote, break-out sessions etc.) and shown to the audience around the venue.',
        points: [
          {
            title: '',
            list: [
              "FanKave's platform powered by Machine Learning would auto-moderate content during the event",
              "Identify Pure Storage's influencers using Social Graph and highlight their content",
              'Daily Social Leaderboard to gamify, incentivize and reward meaningful social engagement',
              'Time-sliced playlist (powered by our Content Management System) to cycle multiple experiences on all the relevant screens to keep the content fresh for the attendees',
              "Designed Call To Action creatives to amplify PureStorage's social handles  ",
            ],
          },
        ],
      },
      resultTitle: 'The Result',
      results: [
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/increase.webp',
          description: (
            <>
              Pure Accelerate's{' '}
              <span className='--blue'>social media experiences</span> received
              tremendous response from attendees and influencers resulting in{' '}
              <span className='--blue'>5X increase in engagement</span> from the
              previous year.
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/metrics.webp',
          description: (
            <>
              Influencer tweets calling out the{' '}
              <span className='--blue'>unique experiences</span> were shown to
              upper management along with metrics as proof of success.
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/automatic.webp',
          description: (
            <>
              Automatically selected content resulting in{' '}
              <span className='--blue'>brand-safe experiences</span>, with
              absolutely no moderation from Pure Storage's social media team,
              was another big highlight that was very well appreciated
            </>
          ),
        },
      ],
      clientsImages: {
        client2: true,
      },
      testimonials: [
        {
          id: 1,
          description:
            "FanKave platform's ability to automatically select the right content that resonated with our audience simplified the management part for our team. Multitude of unique experiences that seamlessly ran across large screens of different sizes was a pleasant surprise. Social Leaderboard definitely made social engagement fun and competitive as we saw increased attendee engagement with lot of people saying good things about the experiences",
          author: 'Ian Wikramanayake',
          designation: 'Social Media Manager, Pure Storage',
          classes: 'card --top-left',
          company: '',
          clientImage: {
            show: false,
            src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/client2.webp',
          },
          extraDesignElement: {
            show: false,
            src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/testimonials-section/commas.webp',
          },
        },
        {
          id: 2,
          description: '',
          author: '',
          designation: '',
          company: '',
          clientImage: { show: false, src: '' },
          extraDesignElement: { show: false, src: '' },
          classes: 'verticle-testimonial',
          contentImage: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/tweet-pure-storage.jpg',
          link: 'https://twitter.com/SFoskett/status/1174348576727719936',
        },
      ],
    },
    {
      id: 3,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/nba-banner.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/nba-banner.webp',
      cardImg: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/fan-engagement-nba.webp',
      cardImgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/fan-engagement-nba.webp',
      title: 'Increase Fan Engagement',
      detailsTitle: (
        <>
          <span className='--white'>Increase Fan Engagement</span>
        </>
      ),
      description:
        'NBA all-star weekend was hosted by the NBA in Los Angeles over a 3 day weekend. There were 100,000 attendees, 30,000 square feet of music, events, sports and entertainment.',
      detailsDescription:
        'NBA all-star weekend was hosted by the NBA in Los Angeles over a 3 day weekend. There were 100,000 attendees, 30,000 square feet of music, events, sports and entertainment.',
      logo: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/nba-logo.webp',
      tags: [],
      stats: [
        { count: '2M+', title: 'Impressions ' },
        { count: '24%', title: 'Increase in engagement' },
        { count: '100K+', title: 'Attendees' },
      ],
      problem: {
        title: 'The Problem',
        description:
          'NBA all-star weekend was hosted by the NBA in Los Angeles over a 3 day weekend. There were 100,000 attendees, 30,000 square feet of music, events, sports and entertainment. The event would be attended by over 500 media personal from 44 countries NBA wanted to create a social wall with real-time fan updates to improve engagement. They had multiple screens both in the hall ways / public dwell areas as well as big jumbotron screens. The most essential requirement for NBA was to avoid any inappropriate content that started popping up on large jumboTron screens during a live family friendly event similar to the one being held. NBA did not want to repeat a similar experience at a much bigger event that could impact the brand negatively. The main objectives were:',
        points: [
          {
            title: '',
            list: [
              'Increase audience engagement.',
              'Create a family friendly experience with no negative sentiments.',
            ],
          },
        ],
      },
      solution: {
        title: 'The Solution',
        description: (
          <>
            FanKave’s solution was initially targeted at 2 small 70” screens in
            the public area outside the main arena. <br />
            On day 1, fans were able to interact with the brand actively.
          </>
        ),
        points: [
          {
            title: '',
            list: [
              'They were shown specific content that highlighted the event taking place, creating buzz around the event and making the content real-time and very effective.',
              'The quality of real-time content filtering was so superior that NBA decided to project it on the big screen (120’ jumboTron) on Day 2 of the event inside the arena.',
              'Fankave’s machine earning algorithm highlighted tweets and content that was most relevant to the event.',
              'The algorithm automatically highlighted tweets from sports star’s and interesting personalities to further intensify the success of the overall event.',
            ],
          },
        ],
      },
      resultTitle: 'The Result',
      results: [
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/increase.webp',
          description: (
            <>
              NBA’s <span className='--blue'>social engagement</span> during the
              event <span className='--blue'>increased 24%</span> at the
              all-star event with over{' '}
              <span className='--blue'>2M impressions</span>
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/monitor-screen.webp',
          description: (
            <>
              All content presented on the{' '}
              <span className='--blue'>jumbotron screens</span> and throughout
              the venue was family friendly and very relevant to the events
              happening in <span className='--blue'>real-time</span>
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/automatic.webp',
          description: (
            <>
              As all the content was{' '}
              <span className='--blue'>automatically generated</span>, this left
              more time and mind share for the{' '}
              <span className='--blue'>social media</span> team to focus on
              other strategies to amplify the event and the brand.
            </>
          ),
        },
      ],
    },
    {
      id: 4,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/cisco-live-banner.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/cisco-live-banner.webp',
      cardImg: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/cisco-live.webp',
      cardImgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/cisco-live.webp',
      title: 'A Virtual Event Benchmark',
      detailsTitle: (
        <>
          <span className='--white'>A Virtual Event Benchmark</span>
        </>
      ),
      description:
        'Cisco’s flagship event, Cisco Live, was moved to a virtual setting in 2020 and 2021 due to Covid. Over 130K attendees were expected from all over the world.',
      detailsDescription:
        'Cisco’s flagship event, Cisco Live, was moved to a virtual setting in 2020 and 2021 due to Covid. Over 130K attendees were expected from all over the world.',
      logo: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/cisco-logo.webp',
      tags: [],
      stats: [
        { count: '10M+', title: 'User Actions' },
        { count: '130K+', title: 'Attendees' },
      ],
      isProblemShort: true,
      problem: {
        title: 'The Problem',
        description:
          'Cisco’s flagship event, Cisco Live, was moved to a virtual setting in 2020 and 2021 due to Covid. Over 130K attendees were expected from all over the world. Event was run on Cisco’s proprietary event platform. Global events team at Cisco, which managed the event, wanted a unique attendee experience with the right mix of social engagement and audience interaction. They did not want yet another virtual event with a bunch of video sessions.',
        points: [],
      },
      solution: {
        title: 'The Solution',
        description:
          'FanKave proposed a complete solution that is both engaging and interactive. We divided our solution in to two areas – Social Engagement and Audience Interaction.',
        points: [
          {
            title: 'Social Media Hub',
            list: [
              'Created a virtual lounge where the attendees can hang out, get the most popular and trending content from social media channels',
              'Created a Virtual Photo Booth with Augmented Reality filters, Cisco Live stickers and background as part of the social media hub to add fun to the attendee experience. Added a photo gallery to show the fun photos from the photo booth',
              'Gamified social engagement by creating two social media leaderboards – one for Cisco employees and one for Cisco clients and partners. Our alogirithms combined the social media posts, their engagement to rank the social media leaders during the event',
            ],
          },
          {
            title: 'Audience Interaction',
            list: [
              'Polls gave attendees an opportunity to give continuous feedback to Cisco and the events team around products, sessions and what they liked plus what they wanted to improve',
              'Trivia and the trivia leaderboard gamified audience interaction during the event',
              'Moderated Q&A gave attendees an easy way interact with speakers during every session. Social signals like upvote were provided to help choose the most popular questions to answer',
              'Total of 98 polls, 84 trivia sessions and 144 moderated Q&A sessions were part of the 3 day event across Americas, Europe and Asia Pacific',
            ],
          },
        ],
      },
      resultTitle: 'Benefits',
      results: [
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/audience.webp',
          description: (
            <>
              <span className='--blue'>Attendee participation</span> numbers
              came out very strong and exceeded the expectations of{' '}
              <span className='--blue'>Global Events</span> team
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/monitor-screen.webp',
          description: (
            <>
              <span className='--blue'>Experienced event professionals</span>{' '}
              who attended the event were very effusive in their praise both of
              the features and the overall{' '}
              <span className='--blue'>attendee experience</span>
            </>
          ),
        },
        {
          icon: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/results-section/metrics.webp',
          description: (
            <>
              <span className='--blue'>FanKave platform</span> seamlessly
              handled <span className='--blue'>heavy traffic</span> throughout
              the <span className='--blue'>event</span>
            </>
          ),
        },
      ],
      clientsImages: {
        client2: false,
      },
      testimonials: [
        {
          id: 1,
          description: '',
          author: '',
          designation: '',
          company: '',
          clientImage: { show: false, src: '' },
          extraDesignElement: { show: false, src: '' },
          classes: 'verticle-testimonial --center',
          contentImage: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/case-studies/tweet-cisco-live.jpg',
          link: 'https://twitter.com/iSocialFanz/status/1272928554544742403',
        },
      ],
    },
  ],
};
