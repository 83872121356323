import { DefaultCard } from 'components';
import { fanExperienceData, caseStudiesData } from 'jsonObjets';

export default function FanExperience() {
  const { title, subHeading, experiences, trustedCompanies } =
    fanExperienceData;
  return (
    <main className='main-fan-experience-section'>
      <section className='main-container fan-experience-container'>
        <h1 className='main-heading --dark'>{title}</h1>
        <section className='cards-container'>
          {experiences.map((experience, index) => (
            <DefaultCard
              caseStudyId={experience.id}
              image={experience.img}
              title={experience.title}
              description={experience.description}
              key={index + experience.title}
              video={experience.video}
              customClasses='--shadow '
            />
          ))}
        </section>

        <section>
          <h2 className='sub-heading --dark'>{subHeading}</h2>
          <article className='trusted-companies-container'>
            {trustedCompanies.map((company, index) => (
              <figure className='image-container' key={index + company.src}>
                <img src={company.src} alt={company.alt} loading='lazy' />
              </figure>
            ))}
          </article>
        </section>
      </section>
    </main>
  );
}
