import React, { useState, useEffect } from 'react';

const CountingAnimation = ({ start = 0, end = 100, duration = 2000 }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const increment = (end - start) / (duration / 100); // Calculate the increment step
    let currentCount = start;
    
    const counter = setInterval(() => {
      currentCount += increment;
      if (currentCount >= end) {
        clearInterval(counter); // Stop animation when it reaches the end
        currentCount = end; // Ensure the count ends exactly at the desired value
      }
      setCount(Math.round(currentCount)); // Update the state and round to nearest whole number
    }, 50); // Update the count every 100ms

    return () => clearInterval(counter); // Clean up the interval on unmount
  }, [start, end, duration]);

  return (
      <span>{count}</span>
  );
};

export default CountingAnimation;