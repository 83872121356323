interface compoenetProps {
  data: {
    title: string;
    count?: string;
    src: string;
    descripion: JSX.Element;
  };
  customClasses?: string;
}
export default function TextWithImageSection(props: compoenetProps) {
  const {
    data: { title, src, descripion, count },
    customClasses,
  } = props;
  return (
    <main className='main-text-with-image'>
      <section
        className={`main-container text-with-image-section ${customClasses}`}
      >
        <img src={src} alt={title} className='image' loading='lazy' />
        <article className='text-section'>
          {/* {count && <h2 className="count">{count}</h2>}
          <h2 className="heading --dark">{title}</h2> */}
          <p className='text'>{descripion}</p>
        </article>
      </section>
    </main>
  );
}
