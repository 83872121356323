import React, { useMemo } from 'react';

type ProblemSolutionType = {
  title: string;
  description: string | JSX.Element;
  points: { title: string; list: string[] }[];
};

type DetailsPageConnectProps = {
  problem: ProblemSolutionType;
  solution: ProblemSolutionType;
  decreaseMargin?: boolean;
  isProblemShort?: boolean;
};

export default function DetailsPageConnect(props: DetailsPageConnectProps) {
  const { problem, solution, decreaseMargin, isProblemShort } = props;

  const renderProblemPoints = useMemo(() => {
    return problem.points.map((point, index) => (
      <div className='p-s-list-section' key={index}>
        {point.title && <h2 className='p-s-list-heading'>{point.title}</h2>}
        <ul className='p-s-list'>
          {point.list.map((listItem, listItemIndex) => (
            <li key={listItemIndex} className='list-item problem-list-item'>
              {listItem}
            </li>
          ))}
        </ul>
      </div>
    ));
  }, [problem.points]);

  const renderSolutionPoints = useMemo(() => {
    return solution.points.map((point, index) => (
      <div className='p-s-list-section' key={index}>
        {point.title && <h2 className='p-s-list-heading'>{point.title}:</h2>}
        <ul className='p-s-list'>
          {point.list.map((listItem, listItemIndex) => (
            <li key={listItemIndex} className='list-item solution-list-item'>
              {listItem}
            </li>
          ))}
        </ul>
      </div>
    ));
  }, [solution.points]);

  return (
    <section
      className={`main-container details-page-connect ${
        decreaseMargin && 'details-page-connect-small'
      }`}
    >
      <section
        className={`problem-solution-container ${
          isProblemShort && 'short-problem-container'
        }`}
      >
        <section className='problem-card'>
          <h1 className='p-s-heading problem-card-heading'>{problem.title}</h1>
          <div className='p-s-description'>{problem.description}</div>
          {renderProblemPoints}
        </section>

        <section className='solution-card'>
          <h1 className='p-s-heading solution-card-heading'>
            {solution.title}
          </h1>
          <div className='p-s-description'>{solution.description}</div>
          {renderSolutionPoints}
        </section>
      </section>
    </section>
  );
}
