export const fanExperienceData = {
  title: (
    <>
      Elevate your <br /> <span className='--blue'>fan experience</span>
    </>
  ),

  subHeading: 'Trusted by companies across the globe',

  experiences: [
    {
      id: 1,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/social-stats-nba.webp',
      title: 'Social Stats',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/social-stats-nba.mp4',
      description:
        'Amplify your brand by showing the most powerful social stats in real time.',
    },
    {
      id: 2,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/social-highlights.webp',
      title: 'Social Highlights',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/social-highlights.mp4',
      description:
        'Aggregate and synthesize the popular, trending and relevant social content from across social channels in real time through our AI engine and present them as immersive branded experiences.',
    },
    {
      id: 3,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trivia.webp',
      title: 'Trivia',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trivia.mp4',
      description: 'Play real time trivia with your fellow fans for rewards.',
    },
    {
      id: 4,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/prediction.webp',
      title: 'Prediction',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/prediction.mp4',
      description:
        'Flexible platform to let your fans pick outcomes of future events.',
    },
    {
      id: 5,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/pollsu.webp',
      title: 'Poll/Survey',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/poll.mp4',
      description:
        'Energize the audience by letting them share their opinions.',
    },
    {
      id: 6,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/personality.webp',
      title: 'Personality Quiz',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/personality.mp4',
      description:
        'Let the fans discover their inner personality through a series of questions.',
    },
    {
      id: 7,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/q%26a.webp',
      title: 'Moderated Q&A',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/qna.mp4',
      description:
        'Fully moderated Q&A and AMA experience to enrich and deepen attendee and fan experience.',
    },
    {
      id: 8,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/ar-photo.webp',
      title: 'Virtual Photo Booth',
      video: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/ar-photo.mp4',
      description:
        'Let the fans share their passion by using AR filters from our Photo Booth.',
    },
  ],

  trustedCompanies: [
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/cisco-logo.webp',
      alt: 'cisco-logo',
    },
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/intel-logo.webp',
      alt: 'intel-logo',
    },
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/linkedin-logo.webp',
      alt: 'linkedin-logo',
    },
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/nba-logo.webp',
      alt: 'nba-logo',
    },
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/pure-storage-logo.webp',
      alt: 'pure-storage-logo',
    },
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/oracle-arena-logo.webp',
      alt: 'oracle-arena-logo',
    },
    {
      src: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/trusted-companies/atp-logo.webp',
      alt: 'cisco-live-logo',
    },
  ],
};
