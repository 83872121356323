import Button from "components/base/button";
import { aboutUsHeroData } from "jsonObjets";

export default function AboutUsHero() {
  const { title } = aboutUsHeroData;
  return (
    <main className="main-about-hero">
      <section className="main-container about-us-section">
        <h1 className="main-heading --dark">{title}</h1>
        <Button link="/about#mail-sent" text="Schedule a demo" />
      </section>
    </main>
  );
}
