import { Tooltip } from 'antd';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useWebPSupportCheck } from 'react-use-webp-support-check';
interface componentProps {
  video?: string;
  caseStudyId?: number;
  image: string;
  title: string;
  description: string;
  customClasses?: string;
  link?: string;
  tags?: string[];
  logo?: string;
  type?: string;
  imageHover?: string;
  customClass?: string;
  enableClick?: boolean;
  imageWebP?: string;
}

export default function DefaultCard(props: componentProps) {
  const {
    image,
    title,
    description,
    customClasses,
    link,
    logo,
    tags,
    type,
    caseStudyId,
    video,
    imageHover,
    customClass,
    enableClick = false,
    imageWebP,
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [play, setPlay] = useState(false);
  const router = useRouter();
  const isWebPSupported = useWebPSupportCheck();

  const tagsList = tags?.map((tag) => tag).join(', ');

  const caseStudyLink = `/case-studies/${caseStudyId}`;

  const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
      const width = hasWindow ? window.innerWidth : null;
      const height = hasWindow ? window.innerHeight : null;
      return {
        width,
        height,
      };
    }

    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      if (hasWindow) {
        const handleResize = () => {
          setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, [hasWindow]);

    return windowDimensions;
  };

  const { height, width } = useWindowDimensions();
  const breakpoint = 768;

  const handlePlay = () => {
    videoRef?.current?.play().catch((error) => {
      console.error('Error attempting to play', error);
    });
    setPlay(true);
  };

  const handleStop = () => {
    videoRef?.current?.pause();
    videoRef?.current?.load();
    setPlay(false);
  };

  const attemptPlay = () => {
    const isMobile = window.matchMedia('only screen and (max-width: 760px)');
    const isMobileDevice = /Mobi|Tablet|iPad|iPhone/i.test(navigator.userAgent);
    if (isMobileDevice || isMobile.matches) {
      videoRef &&
        videoRef.current &&
        videoRef.current.play().catch((error) => {
          console.error('Error attempting to play', error);
        });
    }
  };

  const handleGoToCaseStudy = useCallback(
    (e) => {
      if (!enableClick) e.preventDefault();
      else router.push(caseStudyLink);
    },
    [enableClick, router, caseStudyLink]
  );

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <section
      className={`default-card ${customClasses}`}
      onClick={(e) => handleGoToCaseStudy(e)}
    >
      <figure className='image-container'>
        {video ? (
          <video
            ref={videoRef}
            src={video}
            poster={image}
            className='card-video'
            loop
            muted
            playsInline
            onMouseOver={play === false ? handlePlay : undefined}
            onMouseLeave={play === true ? handleStop : undefined}
          />
        ) : (
          <article className={`card-image ${customClass}`}>
            {isWebPSupported && imageWebP ? (
              <img src={imageWebP} loading='lazy' />
            ) : (
              <img src={image} loading='lazy' />
            )}
            {imageHover && <img src={imageHover} loading='lazy' />}
          </article>
        )}
      </figure>

      <article className='card-text'>
        <h2 className='title'>{title}</h2>
        <p className='description'>{description}</p>
      </article>

      {link && (
        <span onClick={handleGoToCaseStudy} className='link'>
          More
        </span>
      )}

      {type === 'case-study' && (
        <article className='case-study-card-footer'>
          <section className='tags-container'>
            {tags
              ?.filter(
                (tag, index) => index < (width && width < breakpoint ? 3 : 5)
              )
              .map((tag, index) => (
                <span className='tag' key={index + tag}>
                  {tag}
                </span>
              ))}
            {tags && tags?.length > 3 && width && width < breakpoint && (
              <Tooltip title={tagsList} color='blue'>
                <span className='tag --inverse'>{`+${tags?.length - 3}`}</span>
              </Tooltip>
            )}
          </section>
          <img src={logo} alt='' loading='lazy' />
        </article>
      )}
    </section>
  );
}
