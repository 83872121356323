export const heroSectionData = {
  images: [
    {
      src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/cisco.webp",
      alt: "cisco social media center",
      classes: "fade-out --image",
    },
    {
      src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/cisco-nba.webp",
      alt: "cisco social media center",
      classes: "fade-out --image",
    },
    {
      src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/cisco-social-stats.webp",
      alt: "cisco social media center",
      classes: "fade-out --image",
    },
  ],

  relatedData: {
    title: (
      <>
        AI Powered <span className="--blue">Social Engagement</span> & <br />
        <span className="--blue">Audience Interaction</span> platform to
      </>
    ),

    descriptions: [
      {
        src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/first.webp",
        srcWebP: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/first.webp",
        alt: "cisco social media center",
        classes: "fade-out --image",
        text: (
          <span className="fade-out --text ">
            <span className="--blue">Personalize</span> & elevate the fan
            experience
          </span>
        ),
      },
      {
        src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/second.webp",
        alt: "cisco social media center",
        classes: "fade-out --image",
        text: (
          <span className="fade-out --text ">
            <span className="--blue">Monetize</span> fan engagement & create{" "}
            {/* <br /> */}
            new
            <span className="--blue"> sponsorship activations</span>
          </span>
        ),
      },
      {
        src: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/third.webp",
        srcWebP: "https://storage.googleapis.com/fk-gp/websiteAssets/fankave/hero-section/third.webp",
        alt: "cisco social media center",
        classes: "fade-out --image",
        text: (
          <span className="fade-out --text ">
            Seamlessly work across existing
            {/* <br /> */}
            <span className="--blue"> digital channels</span>
          </span>
        ),
      },
      // <span className="fade-out --text ">
      //   <span className="--blue">Monetize</span> fan engagement & create <br />
      //   new
      //   <span className="--blue"> sponsorship activations</span>
      // </span>,
      // <span className="fade-out --text ">
      //   Seamlessly work across existing
      //   <br />
      //   <span className="--blue"> digital channels</span>
      // </span>,
    ],
  },
};
