import { ContactForm } from "forms";
import { MapSection } from "components";

export default function ContactSection() {
  return (
    <section className="contact-section" id='mail-sent'>
      <section className="form-wrapper main-container">
        <ContactForm />
      </section>
      {/* <section className="map-container">
        <MapSection />
      </section> */}
    </section>
  );
}
