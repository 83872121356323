import { useWebPSupportCheck } from 'react-use-webp-support-check';

interface componentProps {
  imageUrl: string;
  imageUrlWebP?: string;
  title: string;
  detailsTitle: string | JSX.Element;
  tags: string[];
  stats: {
    count: string;
    title: string;
  }[];
}
export default function DetailsPageCard(props: componentProps) {
  const {
    imageUrl: img,
    imageUrlWebP: imgWebP,
    title,
    detailsTitle,
    tags,
    stats,
  } = props;
  const isWebPSupported = useWebPSupportCheck();

  return (
    <section className='details-page-card'>
      <figure className='image-wrapper'>
        {isWebPSupported && imgWebP ? (
          <img src={imgWebP} alt={title} className='image' />
        ) : (
          <img src={img} alt={title} className='image' />
        )}
        <figcaption className='image-content'>
          <p className='details-title main-heading'>{detailsTitle}</p>
          <section className='tags-container'>
            {tags?.map((tag, index) => (
              <span className='tag --inverse' key={index + tag}>
                {tag}
              </span>
            ))}
          </section>
        </figcaption>
      </figure>
      <section className='details-page-stats'>
        <section
          className={`stats-container ${stats.length === 2 && 'two-stats'}`}
        >
          {stats.map((stat, index) => (
            <article className='stats' key={index + stat.title}>
              <h1 className='count'>{stat.count}</h1>
              <p className='title'>{stat.title}</p>
            </article>
          ))}
        </section>
      </section>
    </section>
  );
}
