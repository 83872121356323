import React from "react";
import { LoadingSvg } from "svgs";

export default function LoadingScreen() {
  return (
    <section className="loading-screen">
      <LoadingSvg />
    </section>
  );
}
