export const connectSectionData = {
  title: (
    <>
      This is how we <br />
      <span className='--blue'> connect the unconnected</span>
    </>
  ),
  detailsTitle: <>You May Also Like!</>,

  subHeading: 'Trusted by companies across the globe',

  caseStudies: [
    {
      id: 1,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/cisco.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/cisco.webp',
      title: 'Amplify Global Brand',
      description:
        'During Cisco Live, there are more than 1000 sessions, labs, training events that are available to around 35K attendees at the venue.',
    },
    {
      id: 2,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/pure-storage.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/pure-storage.webp',
      title: 'Re-define Social Media Amplification',
      description:
        'In 2019, Pure Storage had its biggest ever annual event in Austin and for the first time, the event marketing team wanted to focus on social media  as one of the primary channels to engage with the attendees.',
    },
    {
      id: 3,
      img: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/fan-engagement-nba.webp',
      imgWebP: 'https://storage.googleapis.com/fk-gp/websiteAssets/fankave/fan-experiences/fan-engagement-nba.webp',
      title: 'Increase Fan Engagement',
      description:
        'NBA all-star weekend was hosted by the NBA in Los Angeles over a 3 day weekend. There were 100,000 attendees, 30,000 square feet of music, events, sports and entertainment.',
    },
  ],
};
